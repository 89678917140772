import React from 'react'
import AddtoCartBtn from '../Util/Buttons/AddtoCartBtn'
import ProductQuanityManager from '../Other/ProductQuanityManager'
import { useDispatch, useSelector } from 'react-redux'
import { removeFromWishlistThunk, wishlistSendToCartThunk } from '../../redux/slice/Wishlist';
import { wishlistToCart } from '../../API/api_request';
import { const_data } from '../../CONST/const_data';
import { toast } from 'react-toastify';
import { fetchCartDetails } from '../../redux/slice/CartItems';

function Wishlist_item({ category, productImage, offer, title, sale_price, originalPrice, product_id, onDelete }) {

    let userState = useSelector((state) => state.userAuth.user)
    let dispatch = useDispatch();

    async function onItemDelete() {
        dispatch(await removeFromWishlistThunk({ product_id }))
        onDelete(product_id);
    }

    async function sentToCart() {
        try {
            await dispatch(wishlistSendToCartThunk({ 
                product_id: product_id, 
                userid: userState?._id, 
                variation: const_data.PRODUCT_VARIATION['1kg'] 
            })).unwrap();
    
            await dispatch(fetchCartDetails()).unwrap();
    
            toast.success("Product sent to cart");
    
            const cartBtn = document.querySelector(".cart__btn");
            if (cartBtn) cartBtn.click();
        } catch (e) {
            console.log(e, "Error");
            toast.error("Failed to add product to cart");
        }
    }


    return (
        <div class="cart-item">
            <div class="cart-product-img">
                <img src={productImage} alt="" />
                <div class="offer-badge">{offer} % OFF</div>
            </div>
            <div class="cart-text">
                <div>
                    <h4>{title}</h4>
                    <div class="cart-item-price">{sale_price} <span>{originalPrice}</span></div>
                    <div className='mt-3'>
                        {/* <ProductQuanityManager currentValue={0} product_id={product_id} ></ProductQuanityManager> */}
                        <AddtoCartBtn onClick={sentToCart}></AddtoCartBtn>
                    </div>
                </div>
                <button onClick={onItemDelete} type="button" class="cart-close-btn"><i class="uil uil-trash-alt"></i></button>
            </div>
        </div>
    )
}

export default Wishlist_item
