import React from 'react'

function WhiteBox({ children }) {
    return (
        //alert-offer
        <div className=' bg-white  border-bottom '> 
            {children}
        </div>
    )
}

export default WhiteBox
