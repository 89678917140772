import React, { Fragment, useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import {
  invoiceEmailUpdate,
  invoicePhoneVerification,
  resendCheckoutVerificationOtp,
} from "../../../API/api_request";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkoutAction } from "../../../redux/slice/CartCheckout";

function PhoneVerification() {
  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  let allStateCheckout = useSelector((state) => state.userCheckout);
  let email = useSelector((state) => state.userCheckout.email);
  let invoiceID = useSelector((state) => state.userCheckout.invoice_id);
  let navigate = useNavigate();
  let [resendOtpAvailable, setReSendOtp] = useState(false);
  let [timer, setTimer] = useState(30);

  let newEmailRef = useRef();
  let dispatch = useDispatch();

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setReSendOtp(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  function updateInvoiceEmail() {
    let newEmail = newEmailRef.current.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (newEmail === email) {
      toast.error("This email is already associated with the checkout verification");
      return;
    }

    if (emailPattern.test(newEmail)) {
      invoiceEmailUpdate(newEmail, invoiceID)
        .then((data) => {
          let responseData = data.data;
          if (responseData?.status) {
            toast.success("Email updated successfully");
            dispatch(checkoutAction.setEmail(newEmail));

            const toggleElement = document.querySelector('[data-toggle="collapse"][href="#edit-number"]');
            if (toggleElement) toggleElement.click();
            newEmailRef.current.value = "";
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    } else {
      toast.error("Email address is not valid");
    }
  }

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
    if (otpValue.length === 4) {
        
      invoicePhoneVerification(otpValue, email, invoiceID)
        .then((dt) => {
          let resultData = dt.data;
          if (resultData?.status) {
            toast.success("OTP Verification success");
            // Trigger the collapse menu
            const nextBtn = document.querySelector(".next-btn16");
            if (nextBtn) nextBtn.click();
            setOtpVerified(true);
          } else {
            toast.error(resultData.msg);
          }
        })
        .catch((err) => {
          toast.error("OTP Verification Failed");
        });
    }
  };

  function resendCheckoutOTP() {
    resendCheckoutVerificationOtp(email, invoiceID)
      .then((data) => {
        let response_data = data?.data;
        if (response_data) {
          if (response_data?.status) {
            toast.success("OTP Resended");
            setTimer(30);
            setReSendOtp(false);
          } else {
            toast.error("OTP Resended Failed");
          }
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  }

  return (
    <Fragment>
      <div className="checkout-card" id="headingOne">
        <span className="checkout-step-number">1</span>
        <h4 className="checkout-step-title">
          <button
            className="wizard-btn"
            type="button"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Email Verification
          </button>
        </h4>
      </div>
      <div id="collapseOne" className="collapse in show" data-parent="#checkout_wizard">
        <div className="checkout-step-body">
          {otpVerified ? (
            <div className="verified-box">
              <p>We've got you covered!</p>
              <p>
                Registered Email: <span className="verified-email">{email}</span>
              </p>
              <p className="verified-status">Verification Successful!</p>
            </div>
          ) : (
            <>
              <p>We need your email so we can inform you about any delay or problem.</p>
              <p className="phn145">
                4 digits code sent to your email <span>{email}</span>
                <a className="edit-no-btn hover-btn" data-toggle="collapse" href="#edit-number">
                  Edit
                </a>
              </p>
              <div className="collapse" id="edit-number">
                <div className="row">
                  <div className="col-lg-10">
                    <div className="checkout-login">
                      <input type="text" className="form-control" ref={newEmailRef} placeholder="New Email" />
                      <button className="chck-btn hover-btn" onClick={updateInvoiceEmail}>
                        Send Code
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="otp-verification">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-0">
                      <label className="control-label">Enter Code</label>
                      <OTPInput
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={4}
                        renderSeparator={<span style={{ width: "10px" }}></span>}
                        renderInput={(props) => (
                          <input
                            {...props}
                            style={{ textAlign: "center", maxWidth: "50px" }}
                            className="form-control input-md"
                          />
                        )}
                      />
                      {resendOtpAvailable ? (
                        <button className="resend-link bg-white" onClick={resendCheckoutOTP}>
                          Resend Code
                        </button>
                      ) : (
                        <button className="resend-link bg-white">Please wait for {timer} seconds</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <a
        className="collapsed next-btn16 hover-btn"
        role="button"
        data-toggle="collapse"
        href="#collapseThree"
        style={{ display: "none" }}
      ></a>
    </Fragment>
  );
}

export default PhoneVerification;
